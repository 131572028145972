export default {
  footer: {
    logos: [
      {
        image: {
          url: '/images/footer/stripe.svg',
          alt: 'Stripe',
          width: 130,
          height: 29,
        },
      },
      {
        image: {
          url: '/images/footer/mastercard-securecode.svg',
          alt: 'MasterCard SecureCode',
          width: 100,
          height: 35,
        },
      },
      {
        image: {
          url: '/images/footer/verified-by-visa.svg',
          alt: 'Verified by Visa',
          width: 100,
          height: 43,
        },
        link: 'https://www.visaitalia.com/paga-con-visa/pagamenti-sicuri/visa-secure.html',
      },
      {
        image: {
          url: '/images/footer/paypal.svg',
          alt: 'Paypal',
          width: 80,
          height: 72,
        },
      },
      {
        image: {
          url: '/images/footer/american-express.png',
          alt: 'American Express',
          width: 107,
          height: 29,
        },
      },
      {
        image: {
          url: '/images/footer/satispay.png',
          alt: 'Satispay',
          width: 136,
          height: 33,
        },
      }
    ],
    links: [
      {
        url: '/condizioni-generali-di-vendita',
        text: 'Termini e condizioni per la vendita di medicinali su Internet',
      },
      {
        url: '/privacy-policy',
        text: 'Informativa sulla privacy',
      },
      {
        url: '/spedizioni-e-consegna',
        text: 'Modalità di spedizione',
      },
      {
        url: '/metodi-di-pagamento',
        text: 'Metodi di pagamento',
      },
    ],
    legalText: "Parafarmacia Autorizzata dal Ministero della Salute codice univoco 016138 | PARAFARMACIA DRMAX TELGATE - via Lombardia, 25/27 - 24060 - Telgate (BG). Farmacista direttore iscritto all’Ordine dei Farmacisti di Agrigento con n. 1019. Puoi verificare l'autorizzazione ministeriale cliccando sul logo sotto.",
  },
}
