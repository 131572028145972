import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
function createSuklWidgetConfig() {
  var codes = ["C016138"];
  var medicalProdIds = [16];
  var vetProdIds = [22];
  var certificates = [].concat(_toConsumableArray(codes.map(function (code) {
    return {
      displayInContext: {
        productAttributeSets: medicalProdIds
      },
      image: {
        url: "/sukl-widget/health-certificate-logo-IT.png",
        alt: "Verifica sito web",
        width: 165,
        height: 138
      },
      link: "https://www.salute.gov.it/portale/temi/soggettoAutorizzatoVenditaOnlineFarmaciMDS.jsp?VOLID=".concat(code)
    };
  })), _toConsumableArray(codes.map(function (code) {
    return {
      displayInContext: {
        productAttributeSets: vetProdIds
      },
      image: {
        url: "/sukl-widget/vet-certificate-logo-IT.png",
        alt: "Verifica sito web",
        width: 165,
        height: 138
      },
      link: "https://www.salute.gov.it/portale/medicinaliVeterinari/dettaglioContenutiMedicinaliVeterinari.jsp?lingua=italiano&id=5907&area=veterinari&menu=vendite&VOLID=".concat(code)
    };
  })));
  return {
    suklWidget: {
      codes: codes,
      medicalProdIds: medicalProdIds,
      vetProdIds: vetProdIds,
      certificates: certificates
    }
  };
}
export default defineAppConfig(createSuklWidgetConfig());