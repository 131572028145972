import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  dataLayerName: 'dataLayerV2',

  tenant: 'IT',
  placementRequestTimeout: 2000,

  placementProductDetailLimit: 16,
  placementCategoryPageLimit: 16,
  placementHomepageLimit: 16,
  placementPreCartLimit: 16,
  placementCartLimit: 16,
  placementELeaderLimit: 1,
  placementTopLimit: 3,
  placementHeroLimit: 1,
  placementBannerWidgetSliderLimit: 12,
  placementBannerWidgetLimit: 3,
  placementCategoryBannerLimit: 1,
})
